<template>
  <contextmenu :disabled="!category" :buttons="buttons">
    <div>
      <img src="@/assets/images/icons/navigation/trash.svg" alt="">
    </div>
    <p :class="$style.text">
      Очистить историю
    </p>
  </contextmenu>
</template>

<script>
import {
  deleteLogsItem,
  deleteLogsCategory,
  deleteComputerLogs,
  deleteLogTypeForAllComputers,
} from '@/api/methods/computers';
import Contextmenu from '@/components/common/contextmenu/index.vue';

export default {
  components: {
    Contextmenu,
  },
  computed: {
    idComputer() {
      return parseInt(this.$route.params.computer, 10);
    },
    category() {
      return this.$route.params.log;
    },
    idItem() {
      return parseInt(this.$route.params.record, 10);
    },
    buttons() {
      return [
        {
          label: 'Удалить текущую запись',
          onClick: this.removeItem,
          disabled: !this.idItem,
        },
        {
          label: 'Очистить выбранную категорию',
          onClick: this.removeCategory,
        },
        //  {
        //      label: 'Очистить выбранную категорию для всех',
        //     onClick: this.removeCategoryForAllComputers,
        //    },
        {
          label: 'Очистить все логи пользователя',
          onClick: this.removeAllComputerLogs,
        },
      ];
    },
  },
  methods: {
    removeItem() {
      deleteLogsItem(this.idComputer, this.category, this.idItem);
      this.$emit('action');
    },
    removeCategory() {
      deleteLogsCategory(this.idComputer, this.category);
      this.$emit('action');
    },
    removeCategoryForAllComputers() {
      deleteLogTypeForAllComputers(this.category);
      this.$emit('action');
    },
    removeAllComputerLogs() {
      deleteComputerLogs(this.idComputer);
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" module>
.text {
  color: $black;
  font-size: 15px;
  line-height: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
