var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-navigation',[_c('section',{class:_vm.$style.page},[_c('header',{class:_vm.$style.header},[_c('div',{class:_vm.$style.control},[_c('date-range-picker',{attrs:{"value":_vm.dateRange,"clearable":false},on:{"inputDateFrom":_vm.dateFromChanged,"inputDateTo":_vm.dateToChanged}})],1),_c('div',{class:_vm.$style.control},[_c('refresh',{class:_vm.$style.mr,attrs:{"text":"Обновить данные"},on:{"click":_vm.refresh}}),(_vm.$store.getters['user/canEdit'])?_c('clean',{on:{"action":_vm.refresh}}):_vm._e(),_c('div',{class:_vm.$style.settings},[_c('img',{class:_vm.$style.img,attrs:{"src":require("@/assets/images/icons/navigation/settings.svg")}}),_c('div',{class:_vm.$style.settingsButton,on:{"click":function($event){return _vm.openModal('settings')}}},[_vm._v(" Настройки представления ")])])],1)]),_c('article',{class:_vm.$style.grid},[_c('div',{class:[
        _vm.$style.gridItem,
        _vm.$style.gridItemComputers,
        ( _obj = {}, _obj[_vm.$style.gridItemComputersCollapsed] = _vm.isComputersCollapsed, _obj ) ]},[_c('div',{class:_vm.$style.gridItemComputersTable},[(!_vm.isComputersCollapsed)?_c('div',{class:_vm.$style.gridItemComputersTableContent},[_c('div',{class:_vm.$style.gridItemComputersTableContentTable},[_c('computers-table',{attrs:{"tree":_vm.tree,"open-elements":_vm.openElements,"on-element-toggle":_vm.onElementToggle}})],1),_c('div',{class:_vm.$style.gridItemComputersTableContentFooter},[_c('button',{class:_vm.$style.gridItemComputersTableContentFooterCollapseButton,on:{"click":function($event){_vm.isComputersCollapsed = true}}},[_vm._v(" Свернуть список компьютеров ")])])]):_vm._e(),(_vm.isComputersCollapsed)?_c('div',{class:_vm.$style.gridItemComputersTableCollapsed},[_c('div',{class:_vm.$style.gridItemComputersTableCollapsedButton,on:{"click":function($event){_vm.isComputersCollapsed = false}}},[_vm._v(" Развернуть список компьютеров ")])]):_vm._e()]),_c('div',{class:_vm.$style.gridItemComputersSidebar},[_c('sidebar')],1)]),_c('div',{class:[
        _vm.$style.gridItem,
        ( _obj$1 = {}, _obj$1[_vm.$style.gridItemComputerCollapsed] = _vm.isComputersCollapsed, _obj$1 ) ]},[_c('div',[_c('div',{class:_vm.$style.computerHeader},[(_vm.selectedComputer)?_c('div',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.selectedComputer.nameUser)+" ")]):_vm._e(),_c('div',{class:_vm.$style.chips},_vm._l((_vm.logTypesFiltered),function(type){return _c('chip',{key:type.id,class:_vm.$style.chip,attrs:{"slug":type.slug,"route":{
                  active: 'computers-computer-log-record',
                  inactive: 'computers',
                }}},[(_vm.selectedComputer && type.hasNewDang)?_c('div',[_c('img',{attrs:{"src":require("@/assets/images/icons/logs/alert.svg")}})]):_vm._e(),_vm._v(" "+_vm._s(type.name)+" "),(_vm.selectedComputer !== null)?_c('div',[_vm._v(" ("+_vm._s(type.count)+") ")]):_vm._e()])}),1),_c('router-view',{attrs:{"name":"control"}})],1),_c('div',{class:_vm.$style.gridItemComputerRouterView},[_c('router-view',{key:_vm.childKey})],1)])])])]),_c('view-categories',{class:_vm.$style.modal,attrs:{"id":"settings"},on:{"close":function($event){return _vm.closeModal('settings')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }