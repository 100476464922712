<template>
  <div>
    <component
      :is="isFolder ? 'div' : 'router-link'"
      :active-class="isFolder ? '' : $style.routerLinkActive"
      :to="{
        name: 'computers-computer',
        params: { computer: id },
        query: $route.query,
      }"
      :class="$style.row"
      @click="toggle"
    >
      <div v-for="v in deep" :key="v" :class="$style.deep" />
      <div
        v-if="hasChildren"
        :class="[$style.expandButton, { [$style.opened]: opened }]"
      />
      <img
        v-if="isFolder"
        :class="$style.iconFolder"
        src="@/assets/images/icons/computer/folder.svg"
      >
      <div
        v-if="hasDangerous"
        :class="[$style.dangerous, dangerousClass]"
        :title="dangerousTitle"
      />
      <div :class="$style.name">
        {{ title }}
      </div>
      <div :class="$style.nameUser">
        <img
          v-if="hasConnection"
          :src="connectionIconUrl"
          :class="$style.connection"
          :title="connectionTitle"
        >
        {{ user }}
      </div>
      <div :class="$style.ip">
        {{ ipAddress }}
      </div>
    </component>
    <div v-if="opened" :class="$style.children">
      <computer
        v-for="item in children"
        :key="`${item.type}-${item.id}`"
        v-bind="item"
        :deep="deep + 1"
        :open-elements="openElements"
        :on-element-toggle="onElementToggle"
      />
    </div>
  </div>
</template>

<script>
const connectionDir = require.context('@/assets/images/icons/computer');

export default {
  name: 'Computer',
  props: {
    id: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (val) => ['computer', 'folder'].includes(val),
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: false,
    },
    statusDangerous: {
      type: [Number, Boolean],
      required: true,
      validator: (val) => [0, 1, 2, false].includes(val),
    },
    nameUser: {
      type: String,
      default: null,
    },
    ipAddress: {
      type: String,
      default: null,
    },
    statusConnection: {
      type: Number,
      default: null,
      validator: (val) => [0, 1, 2, 4].includes(val),
    },
    children: {
      type: Array,
      default: () => [],
    },
    deep: {
      // Свойство не получается из данных, необходимо для отображения
      type: Number,
      default: 0,
    },
    openElements: {
      type: Array,
      required: true,
    },
    onElementToggle: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isFolder() {
      return this.type === 'folder';
    },
    hasChildren() {
      return !!this.children.length;
    },
    hasDangerous() {
      return typeof this.statusDangerous === 'number';
    },
    dangerousClass() {
      switch (this.statusDangerous) {
        case 0:
          return this.$style.green;
        case 1:
          return this.$style.yellow;
        case 2:
          return this.$style.red;
        default:
          return null;
      }
    },
    dangerousTitle() {
      switch (this.statusDangerous) {
        case 0:
          return 'Нарушения отсутствуют';
        case 1:
          return 'Есть нарушения 1-го уровня';
        case 2:
          return 'Есть нарушения 2-го уровня';
        default:
          return null;
      }
    },
    hasConnection() {
      return this.connectionClass !== null;
    },
    connectionTitle() {
      switch (this.statusConnection) {
        case 0:
          return 'Компьютер недоступен';
        case 1:
          return 'Соединение успешно';
        case 2:
          return 'Соединение успешно, мониторинг остановлен';
        case 4:
          return 'Один из процессов агента поврежден или удален';
        default:
          return null;
      }
    },
    connectionIconUrl() {
      if (this.statusConnection !== null) {
        return connectionDir(`./connection-${this.statusConnection}.svg`);
      }

      return null;
    },
    deepArray() {
      const arr = [];
      for (let i = 0; i < this.deep; i++) {
        arr.push(i);
      }
      return arr;
    },
    opened() {
      return !!this.openElements.find(
        (i) => i.id === this.id && i.type === this.type,
      );
    },
  },
  methods: {
    toggle() {
      if (this.hasChildren) {
        this.onElementToggle(this.id, this.type);
      }
    },
  },
};
</script>

<style lang="scss" module>
.row {
  background: white;
  border-top: 1px solid $borderColor;
  color: black;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    background: $light-gray;
  }
}

.routerLinkActive {
  background-color: $light-gray;
}

.name {
  flex: 1 1 100%;
  margin: 6px 0 6px 10px;
}

.nameUser {
  flex: 0 0 150px;
  margin: 6px 0;
}

.ip {
  flex: 0 0 100px;
  margin: 6px 0;
}

.deep {
  flex: 0 0 24px;
}

.expandButton {
  flex-shrink: 0;
  margin: 6px 0 6px 10px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.opened {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.iconFolder {
  margin: 6px 0 6px 10px;
}

.dangerous {
  flex: 0 0 12px;
  height: 12px;
  margin: 6px 0 6px 10px;
  border-radius: 50%;

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }

  &.red {
    background: $red;
  }
}

.connection {
  display: inline-block;
  margin-right: 10px;
}
</style>
