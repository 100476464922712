export class Tree {
  #isActiveElementFound = false;

  openElements = [];

  constructor(idComputer, tree) {
    this.bfs(tree, idComputer);
  }

  bfs(element, activeId) {
    if (!element || element.length === 0 || this.#isActiveElementFound) {
      return;
    }

    if (element.length > 0) {
      element.forEach((item) => {
        this.findOpenElements(item, activeId);
      });
    } else if (Object.prototype.hasOwnProperty.call(element, 'children')) {
      element.children.forEach((item) => {
        this.findOpenElements(item, activeId);
      });
    }
  }

  findOpenElements(item, activeId) {
    if (!this.#isActiveElementFound) {
      this.openElements.push({ id: item.id, type: item.type });
    }

    if (item.id === activeId && item.type === 'computer') {
      this.#isActiveElementFound = true;
    }

    this.bfs(item, activeId);

    if (!this.#isActiveElementFound) {
      this.openElements.pop();
    }
  }
}
