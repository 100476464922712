<template>
  <layout-navigation>
    <section :class="$style.page">
      <header :class="$style.header">
        <div :class="$style.control">
          <date-range-picker
            :value="dateRange"
            :clearable="false"
            @inputDateFrom="dateFromChanged"
            @inputDateTo="dateToChanged"
          />
        </div>

        <div :class="$style.control">
          <refresh
            :class="$style.mr"
            text="Обновить данные"
            @click="refresh"
          />
          <clean
            v-if="$store.getters['user/canEdit']"
            @action="refresh"
          />
          <div :class="$style.settings">
            <img
              :class="$style.img"
              src="@/assets/images/icons/navigation/settings.svg"
            >
            <div
              :class="$style.settingsButton"
              @click="openModal('settings')"
            >
              Настройки представления
            </div>
          </div>
        </div>
      </header>
      <article :class="$style.grid">
        <!-- TODO: Рефакторинг на vue-promised -->
        <div :class="[
          $style.gridItem,
          $style.gridItemComputers,
          { [$style.gridItemComputersCollapsed]: isComputersCollapsed },
        ]"
        >
          <div :class="$style.gridItemComputersTable">
            <div
              v-if="!isComputersCollapsed"
              :class="$style.gridItemComputersTableContent"
            >
              <div :class="$style.gridItemComputersTableContentTable">
                <computers-table
                  :tree="tree"
                  :open-elements="openElements"
                  :on-element-toggle="onElementToggle"
                />
              </div>
              <div :class="$style.gridItemComputersTableContentFooter">
                <button
                  :class="$style.gridItemComputersTableContentFooterCollapseButton
                  "
                  @click="isComputersCollapsed = true"
                >
                  Свернуть список компьютеров
                </button>
              </div>
            </div>
            <div
              v-if="isComputersCollapsed"
              :class="$style.gridItemComputersTableCollapsed"
            >
              <div
                :class="$style.gridItemComputersTableCollapsedButton"
                @click="isComputersCollapsed = false"
              >
                Развернуть список компьютеров
              </div>
            </div>
          </div>
          <div :class="$style.gridItemComputersSidebar">
            <sidebar />
          </div>
        </div>
        <div :class="[
          $style.gridItem,
          { [$style.gridItemComputerCollapsed]: isComputersCollapsed },
        ]"
        >
          <div>
            <div :class="$style.computerHeader">
              <div
                v-if="selectedComputer"
                :class="$style.title"
              >
                {{ selectedComputer.nameUser }}
              </div>
              <div :class="$style.chips">
                <chip
                  v-for="type in logTypesFiltered"
                  :key="type.id"
                  :slug="type.slug"
                  :class="$style.chip"
                  :route="{
                    active: 'computers-computer-log-record',
                    inactive: 'computers',
                  }"
                >
                  <div v-if="selectedComputer && type.hasNewDang">
                    <img src="@/assets/images/icons/logs/alert.svg">
                  </div>
                  {{ type.name }}
                  <div v-if="selectedComputer !== null">
                    ({{ type.count }})
                  </div>
                </chip>
              </div>
              <router-view name="control" />
            </div>
            <div :class="$style.gridItemComputerRouterView">
              <router-view :key="childKey" />
            </div>
          </div>
        </div>
      </article>
    </section>
    <view-categories
      id="settings"
      :class="$style.modal"
      @close="closeModal('settings')"
    />
  </layout-navigation>
</template>

<script>
import dayjs from 'dayjs';
import { Tree } from '@/components/pages/computers/utils';
import LayoutNavigation from '@/layouts/navigation.vue';
import ComputersTable from '@/components/pages/computers/table.vue';
import Clean from '@/components/common/filter/clean.vue';
import Refresh from '@/components/common/filter/refresh.vue';
import DateRangePicker from '@/components/common/filter/separated-date-picker.vue';
import { getComputers, getLogsCount } from '@/api/methods/computers';
import Sidebar from '@/components/pages/computers/sidebar.vue';
import Chip from '@/components/pages/computers/chip.vue';
import ViewCategories from '@/views/computers/view-categories.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

export default {
  components: {
    LayoutNavigation,
    ComputersTable,
    Refresh,
    Clean,
    DateRangePicker,
    Sidebar,
    Chip,
    ViewCategories,
  },
  data: () => ({
    logsInCookies: [],
    updateNeeded: false,
    tree: [],
    computers: [],
    openElements: [],
    isActiveElementFound: false,
    childKey: 0,
    isComputersCollapsed: false,
    interval: null,
    logsCount: [],
    logIdsMapping: {
      1: 1,
      3: 2,
      4: 3,
      5: 4,
      6: 5,
      7: 6,
      10: 7,
      14: 10,
      8: 11,
      15: 13,
      18: 16,
      19: 17,
      2: 19,
      11: 20,
      20: 21,
      21: 22,
      23: 23,
      22: 27,
      24: 29,
      25: 30,
      26: 31,
      27: 32,
    },
  }),
  computed: {
    dateRange() {
      if (!this.$route.query.dateFrom || !this.$route.query.dateTo) {
        this.whenUpdated();
      }
      return [
        new Date(this.$route.query.dateFrom),
        new Date(this.$route.query.dateTo),
      ];
    },
    selectedComputer() {
      if (this.$route.params.computer && this.computers.length > 0) {
        const id = parseInt(this.$route.params.computer, 10);
        const activeItem = this.computers.find((i) => i.id === id);

        if (activeItem) {
          return activeItem;
        }
      }

      return null;
    },
    username() {
      const { selectedComputer } = this;
      return selectedComputer ? selectedComputer.nameComputer : '';
    },
    logTypesFiltered() {
      let logtypes = [];

      const logsInStore = this.$store.getters['user/logsToShow'];
      if (logsInStore) logtypes = logsInStore.slice();
      else if (this.logsInCookies) logtypes = this.logsInCookies.slice();
      else logtypes = this.$store.getters['user/allowedTypes'];

      const { logs } = this.$route.query;

      if (logs) {
        logtypes = logtypes.filter(
          (log) => log.category === logs,
        );
      }

      if (this.logsCount.length > 0) {
        logtypes.forEach((log) => {
          const target = this.logsCount.find((one) => one.tabId === this.logIdsMapping[log.id]);
          if (target) {
            let lastId = 0;
            const compId = parseInt(this.$route.params.computer, 10);

            if (log.slug === 'messengers') {
              const messFiles = this.logsCount.find((one) => one.tabId === 24);
              log.count = target.recordCount + messFiles.recordCount;

              const lastText = this.$store.getters['historyLogs/getLastId'](`${log.slug}text`, compId);
              const lastFile = this.$store.getters['historyLogs/getLastId'](`${log.slug}file`, compId);

              if (target.lastdangId > lastText || messFiles.lastdangId > lastFile) log.hasNewDang = true;
              else log.hasNewDang = false;
            } else {
              log.count = target.recordCount;

              lastId = this.$store.getters['historyLogs/getLastId'](log.slug, compId);

              if (target.lastdangId > lastId) log.hasNewDang = true;
              else log.hasNewDang = false;
            }
          }
        });
      }
      return logtypes;
    },
  },
  watch: {
    selectedComputer() {
      this.getLogsCountAndDang();
      this.setPrintScreenshotsData();
    },
    updateNeeded(value) {
      if (value) {
        this.fetch().then(({ tree, computers }) => {
          if (this.$route.params.computer && computers.length) {
            this.setPrintScreenshotsData();
            const treeInstance = new Tree(
              parseInt(this.$route.params.computer, 10),
              tree,
            );
            this.openElements = treeInstance.openElements;
          }
        });

        this.updateNeeded = false;
      }
    },
  },
  created() {
    this.logsInCookies = this.$cookies.get('logsToShow');
    this.whenUpdated();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    whenUpdated() {
      const queryMutations = {};
      const defaultDateQuery = dayjs().format('YYYY-MM-DD'); // TODO: Унификация формата даты

      if (!this.$route.query.dateFrom) {
        queryMutations.dateFrom = defaultDateQuery;
      }

      if (!this.$route.query.dateTo) {
        queryMutations.dateTo = defaultDateQuery;
      }

      if (Object.keys(queryMutations).length) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...queryMutations,
          },
        });
      }

      this.updateNeeded = true;
    },
    async getLogsCountAndDang() {
      const dateFrom = dayjs(this.$route.query.dateFrom)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);

      const dateTo = dayjs(this.$route.query.dateTo)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 0);
      const result = await getLogsCount(this.selectedComputer.id, dateFrom, dateTo);
      this.logsCount = result.result;
    },
    pollData() {
      this.interval = setInterval(this.fetch, 7000);
    },
    // FIXME: Метод может вызываться два раза при первой загрузке страницы
    setPrintScreenshotsData() {
      const computer = this.selectedComputer;
      this.$store.commit(
        'printScreenshots/nameComputer',
        computer ? computer.nameComputer : '',
      );
      this.$store.commit(
        'printScreenshots/ipComputer',
        computer ? computer.ipAddress : '',
      );
      this.$store.commit(
        'printScreenshots/macComputer',
        computer ? computer.macAddress : '',
      );
    },
    onElementToggle(id, type) {
      const index = this.openElements.findIndex(
        (i) => i.id === id && i.type === type,
      );
      if (index !== -1) {
        this.openElements.splice(index, 1);
      } else {
        this.openElements.push({ id, type });
      }
    },
    setNodeAttributes(node) {
      if (node.type === 'folder') {
        node.title = node.name;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        node.title = node.nameUser;
        node.user = node.name;
      }
    },
    fetch() {
      return getComputers().then(({ tree, computers }) => {
        tree.forEach((item) => this.setNodeAttributes(item));
        this.tree = tree;
        this.computers = computers;
        return { tree, computers };
      });
    },
    refresh() {
      this.fetch();
      this.childKey += 1;
    },
    dateRangeChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateFrom: dayjs(val[0]).format('YYYY-MM-DD'), // TODO: Унификация формата даты
          dateTo: dayjs(val[1]).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
    dateFromChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateFrom: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
    dateToChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateTo: dayjs(val).format('YYYY-MM-DD'), // TODO: Унификация формата даты
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.page {
  background: $white;
  width: 100%;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.grid {
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  display: flex;
  background: white;
}

.gridItem {
  flex: 0 0 50%;
}

.gridItemComputers {
  display: flex;
}

.gridItemComputersCollapsed {
  flex: 0 1 0%;
}

.gridItemComputerCollapsed {
  flex: 1 1 100%;
}

.gridItemComputersTable {
  flex: 1 1 100%;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid $borderColor;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
}

.gridItemComputersTableContentFooter {
  border-top: 1px solid $borderColor;
  position: sticky;
  bottom: 0;
}

.gridItemComputersTableContentFooterCollapseButton {
  display: block;
  width: 100%;
  border: 0;
  background: $light-blue;
  cursor: pointer;
}

.gridItemComputersTableCollapsed {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100%;
  border-top: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  width: 48px;
}

.gridItemComputersTableCollapsedButton {
  cursor: pointer;
  background: $light-blue;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  bottom: 0;
  padding: 20px 0;
}

.gridItemComputersSidebar {
  flex-shrink: 0;
}

.control {
  display: flex;
  align-items: center;

  .mr {
    margin-right: 30px;
  }
}

.computerHeader {
  padding: 20px 20px 25px 20px;
  border: 1px solid $borderColor;
  border-bottom-width: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 20px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: 10px; // 20px total
}

.settings {
  display: flex;
  margin-left: 30px;
  cursor: pointer;
}

.settingsButton {
  margin-left: 10px;
  font-size: 15px;
  line-height: 18px;
}

.modal {
  display: none;
  z-index: 3;
}
</style>
