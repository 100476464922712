<template>
  <div :class="$style.table">
    <div :class="$style.header">
      <div :class="$style.name" @click="toggleSort('title')">
        Название
        <div
          v-if="sortField === 'title'"
          :class="[$style.sort, { [$style.desc]: !isSortOrderAsc }]"
        >
          ▾
        </div>
      </div>
      <div :class="$style.nameUser" @click="toggleSort('user')">
        Имя компьютера
        <div
          v-if="sortField === 'user'"
          :class="[$style.sort, { [$style.desc]: !isSortOrderAsc }]"
        >
          ▾
        </div>
      </div>
      <div :class="$style.ip" @click="toggleSort('ipAddress')">
        IP адрес
        <div
          v-if="sortField === 'ipAddress'"
          :class="[$style.sort, { [$style.desc]: !isSortOrderAsc }]"
        >
          ▾
        </div>
      </div>
    </div>
    <computer
      v-for="item in treeSorted"
      :key="`${item.type}-${item.id}`"
      v-bind="item"
      :open-elements="openElements"
      :on-element-toggle="onElementToggle"
    />
  </div>
</template>

<script>
import Computer from './computer.vue';

export function sortTree(tree, field, isAsc) {
  let currentField = field;

  return [...tree].sort((a, b) => {
    if (a.children && a.children.length) {
      a.children = sortTree(a.children, field, isAsc); // eslint-disable-line no-param-reassign
      currentField = 'title';
    }

    if (b.children && b.children.length) {
      b.children = sortTree(b.children, field, isAsc); // eslint-disable-line no-param-reassign
      currentField = 'title';
    }

    let result = 0;

    if (a.type !== b.type) {
      result = a.type === 'folder' ? -1 : 1;
    } else if (a[currentField] > b[currentField]) {
      result = 1;
    } else if (a[currentField] < b[currentField]) {
      result = -1;
    }

    return isAsc ? result : result * -1;
  });
}

export default {
  components: {
    Computer,
  },
  props: {
    tree: {
      type: Array,
      required: true,
    },
    openElements: {
      type: Array,
      required: true,
    },
    onElementToggle: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    sortField: 'title',
    isSortOrderAsc: true,
  }),
  computed: {
    treeSorted() {
      const { tree, sortField, isSortOrderAsc } = this;

      return sortTree(tree, sortField, isSortOrderAsc);
    },
  },
  methods: {
    toggleSort(field) {
      if (this.sortField === field) {
        this.isSortOrderAsc = !this.isSortOrderAsc;
      } else {
        this.sortField = field;
        this.isSortOrderAsc = true;
      }
    },
  },
};
</script>

<style lang="scss" module>
.table {
  border: 1px solid $borderColor;
  border-left: none;
  border-right: none;
}

.header {
  background: $light-gray;
  color: $black;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.name {
  cursor: pointer;
  flex: 1 1 100%;
  margin-left: 10px;
}

.nameUser {
  cursor: pointer;
  flex: 0 0 150px;
}

.ip {
  cursor: pointer;
  flex: 0 0 100px;
}

.sort {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 4px;
  color: $dark-gray;

  &.desc {
    transform: rotate(180deg);
  }
}
</style>
