<template>
  <div :class="$style.sidebar">
    <router-link
      v-if="isShown('events')"
      replace
      :class="[$style.icon, { [$style.active]: category === 'events'} ]"
      title="События"
      :to="getRouterLink('events')"
    >
      <inline-svg :src="require('@/assets/images/icons/computer/categories/events.svg')" />
    </router-link>
    <router-link
      v-if="isShown('internet')"
      replace
      :class="[$style.icon, { [$style.active]: category === 'internet'} ]"
      title="Интернет"
      :to="getRouterLink('internet')"
    >
      <inline-svg :src="require('@/assets/images/icons/computer/categories/internet.svg')" />
    </router-link>
    <router-link
      v-if="isShown('devices')"
      replace
      :class="[$style.icon, { [$style.active]: category === 'devices'} ]"
      title="Устройства"
      :to="getRouterLink('devices')"
    >
      <inline-svg :src="require('@/assets/images/icons/computer/categories/devices.svg')" />
    </router-link>
    <router-link
      replace
      :class="[$style.icon, { [$style.active]: !category} ]"
      title="Все категории"
      :to="getRouterLink()"
    >
      <inline-svg :src="require('@/assets/images/icons/computer/categories/all.svg')" />
    </router-link>
  </div>
</template>

<script>
// TODO: Название компонента не отражает функционал
import { InlineSvgComponent } from 'vue-inline-svg';

export default {
  components: {
    inlineSvg: InlineSvgComponent,
  },
  computed: {
    category() {
      return this.$route.query.logs;
    },
  },
  methods: {
    getRouterLink(logs) {
      return {
        query: {
          ...this.$route.query,
          logs,
        },
      };
    },
    isShown(category) {
      return (
        this.$store.getters['user/allowedTypes'].findIndex(
          (i) => i.category === category,
        ) !== -1
      );
    },
  },
};
</script>

<style lang="scss" module>
.sidebar {
  border-top: 1px solid $borderColor;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: $light-blue;

    svg path {
      fill: $blue;
    }
  }

  &:last-of-type {
    margin-bottom: auto;
  }
}
</style>
