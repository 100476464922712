<template>
  <modal
    header-text="Настройки представления"
    button-text="Сохранить"
    @action="saveSettings()"
    @close="$emit('close')"
  >
    <div :class="$style.header">
      Показать следующие вкладки:
    </div>
    <div
      :class="[$style.checkAll, $style.customCheckBoxContainer]"
      @click="checkAllLogs()"
    >
      <input
        v-model="isAllLogsChecked"
        type="checkbox"
        :value="isAllLogsChecked"
        :class="$style.customCheckbox"
      >
      <label><check-mark /></label>
      <div>Выбрать все</div>
    </div>
    <div :class="$style.grid">
      <div
        v-for="log in logTypes"
        :key="log.id"
        :class="[$style.gridItem, $style.customCheckBoxContainer]"
        @click="check(log)"
      >
        <input
          :id="log.id"
          v-model="checkedLogs"
          type="checkbox"
          :value="log"
          :class="$style.customCheckbox"
        >
        <label><check-mark /></label>
        <div>{{ log.name }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/category-modal.vue';
import CheckMark from '@/components/common/filter/check-mark.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

export default {
  components: { Modal, CheckMark },
  data: () => ({
    checkedLogs: [],
    isAllLogsChecked: false,
  }),
  computed: {
    logTypes() {
      return this.$store.getters['user/allowedTypes'];
    },
  },
  watch: {
    checkedLogs(logs) {
      if (logs.length === this.logTypes.length) this.isAllLogsChecked = true;
      else this.isAllLogsChecked = false;
    },
  },
  created() {
    const logs = this.$cookies.get('logsToShow');

    if (logs) this.checkedLogs = logs.slice();
    else this.checkedLogs = this.logTypes.slice();
  },
  methods: {
    checkAllLogs() {
      if (this.logTypes.length === this.checkedLogs.length) {
        this.checkedLogs = [];
        this.isAllLogsChecked = false;
      } else {
        this.checkedLogs = this.logTypes.slice();
        this.isAllLogsChecked = true;
      }
    },
    check(log) {
      const index = this.checkedLogs.map((e) => e.id).indexOf(log.id);

      if (index === -1) {
        this.checkedLogs.push(log);
      } else this.checkedLogs.splice(index, 1);
    },
    close() {
      this.$emit('close');
    },
    saveSettings() {
      this.$store.commit('user/setLogsToShow', this.checkedLogs);
      this.$cookies.set('logsToShow', this.checkedLogs, Infinity);
      this.close();
    },
  },
};
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 15px;
  gap: 0px 15px;
}

.gridItem {
  margin: 10px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  gap: 7px;
  display: flex;
  align-items: center;
}

.header {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  margin: 0px 20px;
}

.customCheckBoxContainer {
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input:checked+label>svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }

  label:active::after {
    background-color: #fbfbfb;
  }

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }

  input:checked+label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

.checkAll {
  display: flex;
  align-items: center;
  margin: 25px;
  gap: 7px;
  cursor: pointer;
}
</style>
